import request from '../utils/request.js'
// pdf笔记功能相关接口

// 新增笔记接口
export function uploadNote (data) {
  return request({
    url: '/home/note/uploadNote',
    method: 'POST',
    data
  })
}

// 获取一篇文献的笔记列表
export function getNotesByDocumentId (data) {
  return request({
    url: '/home/note/getNotesByDocumentId',
    method: 'POST',
    data
  })
}

// 删除笔记
export function deleteNote (data) {
  return request({
    url: '/home/note/deleteNote',
    method: 'POST',
    data
  })
}

// 获取用户有笔记的文献列表
export function getNotedDocuments (data) {
  return request({
    url: '/home/note/getNotedDocuments',
    method: 'POST',
    data
  })
}
