<template>
  <!-- 个人中心我的笔记  -->
  <div class="my-note loading">
    <div class="my-note-item mb" v-for="(item, i) in list" :key="i">
      <div class="top mb">共{{item.noteCount}}个笔记</div>
      <div class="title mb" @click="goNoteDetails(item.documentId)">{{item.documentTitle}}</div>
    </div>
    <placeholder :isShow="isShow" title="快去浏览喜欢的内容吧" />
  </div>
</template>
<script>
import { getNotedDocuments } from '@/api/note.js'
export default {
  data () {
    return {
      list: [],
      isShow: false
    }
  },
  methods: {
    // 获取有笔记的文章列表
    async getNotedList () {
      const res = await getNotedDocuments()
      this.isShow = res.data.length === 0
      this.list = res.data
    },
    // 跳转到笔记详情页
    goNoteDetails (id) {
      this.$router.push({ path: '/noteDetails', query: { id } })
    }
  },
  created () {
    this.getNotedList()
  }
}
</script>
<style lang="less" scoped>
.my-note {
  width: 100%;
  height: 100%;
}

.my-note-item {
  border-radius: 8px;
  overflow: hidden;
  font-size: 16px;
  padding: 0 0 1px;
  background-color: #fff;
  &:hover {
    box-shadow: 6px 6px 7px 0 rgba(204, 204, 204, 0.5);
    transition: all 0.3s;
  }
  .top {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    padding-left: 20px;
    color: #000;
    background-color: #d7dce9;
  }
  .title {
    width: 100%;
    font-size: 20px;
    padding: 0 20px;
    color: #4b639f;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .if-doi {
    padding: 0 20px;
    span {
      margin-right: 30px;
    }
  }
  .doi {
    padding: 0 20px;
  }
  .docAuthor {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #666;
    padding: 0 20px;
    line-height: 20px;
    font-size: 16px;
  }
}

.color {
  color: #666;
}
.mb {
  margin-bottom: 30px;
}
.el-alert {
  margin-bottom: 20px;
  background-color: #fff;
  /deep/.el-alert__title {
    font-size: 14px;
  }
}
// 缺省样式
.lack {
  text-align: center;
  font-size: 16px;
  height: 50px;
}
</style>
